import React from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import { IFooter } from '../../types/strapi/global';
import { isEn, TLocale } from '../../utils/i18n';
// import agreement from '../cookie-alert/assets/agreement.pdf';
import GridWrapper from '../grid-wrapper';
import Text from '../text';

import './index.css';

const rootClassName = 'footer-disclamer';

interface IFooterProps {
    data: IFooter,
    disclamerClassName?: string,
    lang?: TLocale
}

const FooterDisclamer = ({ disclamerClassName, data, lang = 'ru' }: IFooterProps) => {
    const cn = useClassnames();
    const { disclaimer, copyright, coreBusiness, documentsText } = data;

    return (
        <GridWrapper className={cn(rootClassName)}>
            <Text
                className={cn(
                    `${rootClassName}__disclaimer`,
                    disclamerClassName
                )}
                as="p"
                size={5}
                dangerouslySetInnerHTML={{ __html: disclaimer }}
            />

            <Text
                className={cn(
                    `${rootClassName}__disclaimer`,
                    disclamerClassName
                )}
                size={5}
            >
                <p dangerouslySetInnerHTML={{ __html: disclaimer }} />
                <p>{coreBusiness}</p>
            </Text>

            <Text
                className={cn(`${rootClassName}__copyright`)}
                as="span"
                size={5}
            >
                {copyright}
            </Text>

            {/* <Text className={cn(`${rootClassName}__legal-info`)} size={5}>
                {documentsText && (
                    <a
                        className={cn(`${rootClassName}__documents`)}
                        href={lang === 'ru' ? '/documents' : '/en/documents'}
                    >
                        {documentsText}
                    </a>
                )}

                <a
                    target="_blank"
                    className={cn(`${rootClassName}__documents`)}
                    href={agreement}
                >
                    {lang === 'ru' ? 'Политика конфиденциальности' : 'Privacy policy'}
                </a>
            </Text> */}
        </GridWrapper>
    );
};

export default FooterDisclamer;
